import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';
import {Helmet} from "react-helmet";
import image4 from '../assets/img/Picture14.jpg'
import image5 from '../assets/img/Picture21.jpg'

const Article5 = () => {



  return (
    <>   
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  MANiBOT Joins the First Automation and Robotics Expo in Athens        
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 07/05/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={image4} title=""  height="300" />
                <p style={{textAlign:'justify'}}>
               
                Project Coordinator CERTH showcased MANiBOT at the first <a href='https://ar-expo.gr/en/homepage/'>Automation and Robotics Expo</a> at the Metropolitan Expo Center in Athens, Greece in mid April. Over 120 exhibitors and more than 8000 visitors from industry, technology, academia and education gathered to discover the latest research and products in a vibrant atmosphere of technological innovation.
</p>


<p style={{textAlign:'justify'}}>CERTH’s stand featured their centre’s work and various collaborative initiatives, with MANiBOT’s presentation and leaflets shared with visitors during exchanges with CERTH’s representatives. Throughout the three days of the show, they engaged in insightful discussions with industry, experts, and enthusiastic visitors around MANiBOT's vision to equip mobile service robots with human-like capabilities to manipulate a wide range of objects in dynamic environments.  </p>
<img style={{float:'right',margin:' 0px  20px 0px 20px'}} src={image5} title="" height="210" />
<p style={{textAlign:'justify'}}>Sections of the expo were dedicated to automation, robotics, artificial intelligence and drones and new technologies such as cloud computing, augmented virtual reality, web and mobile apps, big data analytics and 3D printing. The gathering was a unique opportunity to raise awareness of the project’s work and objectives to a wide audience.  </p>
              
                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
    </>
  )
}

export default Article5