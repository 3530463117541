import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { HashLink } from "react-router-hash-link";
import acin from '../assets/img/logos/ACIN.jpg'
import aristotle from '../assets/img/logos/aristotle2.png'
import tu from '../assets/img/logos/TULogo.jpg'
import fraport from '../assets/img/logos/fraport1.png'
import masoutis from '../assets/img/logos/logoMasoutis_white.jpg'
import sdi from '../assets/img/logos/sdi_logo_primary_cmyk_01.jpg'
import ssa from '../assets/img/logos/SSSA_logo.png'
import Ciop from '../assets/img/logos/CIOP_PIB.png'
import burgos from '../assets/img/logos/UoBurgos logo.png'
import ABB from '../assets/img/logos/abbb.png'
import bristol from '../assets/img/logos/university-of-bristol-logo-png-transparent.png'
import iti from '../assets/img/logos/ITI_logo_EN.jpg'
import certh from '../assets/img/logos/CERTH_Logo.png'
import tuda from '../assets/img/logos/tuda_logo.png'

import twi from '../assets/img/logos/TWI-Hellas.png'
import './carousel.scss'

export default class CenterMode extends Component {
	render() {
		const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay:0,
      slidesToShow: 4,
      autoplaySpeed: 5000,
      infinite: true,
      speed: 2000,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1324,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };
		return (
			<div className="container">
				<link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
				<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
				<style>{cssstyle}</style>

				<Slider {...settings}>

				

					<li>
					<div  id='correctionlist'>
				
					<HashLink  to="/consortium#aristotle">
					  <img id='carousimg' src={aristotle} alt="" className="centered-element" />
					</HashLink>
					</div>
					</li>

					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium#masoutis">
          <img id='carousimg' src={masoutis} alt="" className="centered-element"/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium#sdi">
          <img id='carousimg' src={sdi} alt="" className="centered-element"/>
					</HashLink>
						</div>
					</li>

					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium#fraport">
          <img id='carousimg' src={fraport} className="centered-element" alt="" style={{marginTop:'10px'}}/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium#tu">
          <img id='carousimg' src={tu} alt="" className="centered-element"/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium#twi">
          <img id='carousimg' src={twi} alt="" className="centered-element"/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium#ssa">
          <img id='carousimg' src={ssa} alt="" className="centered-element"/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium#certh">
          <img id='carousimg' src={certh} alt="" className="centered-element"/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium#burgos">
          <img id='carousimg' src={burgos} alt="" className="centered-element"/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium#ciop">
          <img id='carousimg' src={Ciop} alt="" className="centered-element"/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium#bristol">
          <img id='carousimg' src={bristol} alt="" className="centered-element"/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium#abb">
          <img id='carousimg' src={ABB} alt="" className="centered-element" style={{padding:'30px'}}/>
					</HashLink>
					</div>
					</li>
					<li>
						<div  id='correctionlist'>
					<div class="circle">
<span>
</span>
</div>
					<HashLink  to="/consortium#abb">
          <img id='carousimg' src={tuda} alt="" style={{marginTop:'9px',width:"180px"}}/>
					</HashLink>
					</div>
					</li>


				</Slider>
			</div>
		);
	}
}

const cssstyle = `
.container {
  margin: 0 auto;
  padding: 0px 40px 40px 40px;
}
# {
	color: #fff;
	    font-size: 36px;
	    margin-left: auto;
	    margin-right: auto;
	    display: block;
	    max-width: 140px;
	    min-width: 90px;
	    line-height: 100px;
	    /* margin: 10px; */
	    margin-top: -194px;
	    margin-left: 29px;
	    /* padding: 2%; */
	    /* z-index: 1000; */
	    position: fixed;
	    text-align: center;
}
.slick-next:before, .slick-prev:before {
    color: #000;
}
`
