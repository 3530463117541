import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../assets/scss/newstyle.scss'
import blogimg1 from '../assets/img/Picture2.jpg'


const BlogList = () => {




  return (
    <div id="layout" className="pure-g">
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts" >

		  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>

		<article class="postcard light blue">

			<div class="postcard__text t-dark">
			<p style={{fontSize:'12px',fontWeight:'600',color:'#5072a4',textAlign:'left'}}>PRESS RELEASE</p>
				<h1 class="postcard__title blue">Newly launched pioneering project promises a step change in bi-manual mobile robots</h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						27/12/2023
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">The EU-funded MANiBOT project, aiming to empower service robots with superior physical capabilities, was launched  at the Centre for Research and Technology Hellas (CERTH) in Thessaloniki, Greece, in late 2023.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/Newly launched pioneering project promises a step change in bi-manual mobile robots'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>

         
          </div>


          
        </div>
      </div>
    </div>
  )
}

export default BlogList