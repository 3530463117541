import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';
import {Helmet} from "react-helmet";

const Article1 = () => {



  return (
    <>   
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  Newly launched pioneering project promises a step change <br/>in bi-manual mobile robots
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 27/12/2023
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <p style={{textAlign:'justify'}}>
                The EU-funded MANiBOT project, aiming to empower service robots with superior physical
capabilities, was launched at the Centre for Research and Technology Hellas (CERTH) in Thessaloniki,
Greece, in late 2023.
</p>
<p style={{textAlign:'justify'}}><b>
MANiBOT aims to equip mobile service robots with the capacity to handle a wide variety of
unknown objects in a human-like way, in environments where people are also present. This will
unlock the true potential of these robots, becoming attractive to new, major industry and service
sectors.</b>
</p>
<p style={{textAlign:'justify'}}>
Despite advances in robotic perception, understanding and control, collaborative service robots still
have limited physical performance compared to that of humans. Industrial-grade robots
demonstrate strength, dexterity and speed but only in the context of handling well-known objects in
controlled environments.
</p> <p style={{textAlign:'justify'}}>
The project seeks to revolutionise the robotics landscape by enhancing robot's handling skills,
including simple grasping, pick-and-place operations, bi-manual and non-prehensile manipulation,
and ensuring adaptive responses to changing environments or to the properties of objects. To
achieve these capabilities, innovations will be developed in the fields of advanced environment
understanding, efficient manipulation techniques, robot cognitive functions and physical
intelligence. The researchers will implement their solutions with a focus on baggage handling and
supermarket shelves’ restocking, with piloting of the robots in relevant environments.</p>

<p style={{textAlign:'justify'}}>MANiBOT brings together the multidisciplinary expertise of key players in the research of robotic
technologies from seven countries. The consortium is led by research institute CERTH, and its
expertise is complemented by the Central Institute for Labour Protection - National Research
Institute and six universities: Aristotle University of Thessaloniki, Technical University of Darmstadt,
Technical University of Vienna, University of Bristol, University of Burgos, along with one specialised
SME TWI Hellas, leading industry actor Asea Brown Boveri SA and commercial partners Fraport
Regional Airports of Greece Management, Diamantis Masoutis Supermarkets and Schwarz Digital GmbH & Co. KG.</p>
<p style={{textAlign:'justify'}}>Dr. Dimitrios Giakoumis from CERTH, the project Coordinator, explains “the MANiBOT vision is of
service robots capable of manipulating diverse and not necessarily well-known objects, efficiently, in
a human-like manner”. He added, “to achieve this, MANiBOT aims to advance Individual
technologies for robot perception, cognition and bi-manual manipulation, as well as their coupling”.</p>

                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
    </>
  )
}

export default Article1