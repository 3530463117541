import React from 'react'
import gold from './goldlogo.png'
import { Slide } from 'react-slideshow-image';
import Fade from 'react-reveal/Fade';
import image2 from './image4.png'

import Slideshow from './slideshow.js'

function Head() {
  return (
    <header id='head'>
      
    <div class="containernew">
    
    <section class="showcase" >
      <div style={{marginTop:'50px',padding:'5%',fontSize:'smaller'}}>
        <Fade duration={2000}>
        </Fade>
        <Fade duration={3000}>
      
        </Fade>
        <Fade duration={5000}>
        
       
        </Fade>
        <div class="home-main__split home-main__split--left">
        <span class="home-main__small-text">scroll down</span>
      </div>
        </div>
        <img src={image2} style={{width:'0px',opacity:'0'}}></img>

    </section>
    
</div>

<div class="video-container">
    <Slideshow/>
</div>
</header>


)}
export default Head;