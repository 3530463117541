import React from "react";
import "../../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'
import Mapchart from '../map.js'
import signature from "../signaturejs.js";
import CookieConsent, { Cookies } from "react-cookie-consent";
import ContactForm from './contact'
import DarkFooter from "../../components/Footers/DarkFooter.js";
import cons from '../cons.png'
import test from '../../assets/img/test2.svg'
import { HashLink } from "react-router-hash-link";
import CustomForm from '../../components/SubscribeForm'
import lamp from '../../assets/img/lamp.svg'
import { Link } from 'react-router-dom'
import new1 from '../../assets/img/new1.jpg'
import new2 from '../../assets/img/new2.jpg'
import eye from '../../assets/img/eye.svg'
import brain from '../../assets/img/brain.svg'
import gripper from '../../assets/img/gripper.svg'


// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Carousel from "../../components/carousel.js";


const url = "https://gmail.us14.list-manage.com/subscribe/post?u=047540e6f745f9512f7cbb36e&amp;id=a0467af439&amp;f_id=00faf4e0f0";

const SimpleForm = () => <MailchimpSubscribe url={url}/>

function LandingPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        
        <LandingPageHeader i />
        
        <section className="section section-about-us" id='summary' style={{padding:'30px 0px'}}>
          <Container>
            <div>
          <h3 style={{fontSize:'24px',color:'#2f5597',textAlign:'center',fontStyle:'italic'}}>
        Pioneering Bimanual Mobile Robots for Challenging Work Environments</h3>
</div>
        
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
              <div class="title">
    <h2 className="title" style={{color:'white',textAlign:'left'}} class="hr"><span style={{color:'#163365'}}>Project Summary</span></h2>
  </div>
                
                <h5 className="description" style={{textAlign:'justify',color:'#2f5597'}}>
                <p>
                MANiBOT aims to empower bi-manual, mobile, service robots with superior physical capabilities
able to perform a wide variety of manipulation tasks, with highly diverse objects, in a human-like
manner and performance, in diverse, challenging environments.</p>
<p>
The project seeks to revolutionise the robotics landscape by enhancing robot's handling skills,
including simple grasping, pick-and-place operations, bi-manual and non-prehensile manipulation,
and ensuring adaptive responses to changing environments or to the properties of objects.</p>
<p>
To achieve these capabilities, innovations will be developed in the fields of advanced environment
understanding, efficient manipulation techniques, robot cognitive functions and physical
intelligence. The researchers will implement their solutions with a focus on baggage handling and
supermarket shelves’ restocking, with piloting of robots in relevant environments.</p>
               
                </h5>
              </Col>
            </Row>
            </Container>
            </section>
            <section className="section section-about-us" id='why' style={{textAlign:'justify',backgroundColor:'#163365'}}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12" >
              <div class="title">
    <h2 className="title" style={{color:'white',textAlign:'left'}} class="hr"><span style={{color:'whitesmoke'}}>Why MANiBOT?</span></h2>
  </div>
                <h5 className="description" style={{color:'white',textAlign:'justify'}}>
                  <div>
                <img style={{float:'right', borderRadius:'20px'}} src={new1} id='img1'/></div>
                  <div>
              <p>
              Despite advances in robotic perception, understanding and control, collaborative service robots still
demonstrate limited physical performance compared to that of humans. This is particularly the case
when it comes to the sought-after capability of safe and efficient robot-environment interaction
with diverse object manipulation in real, human-populated spaces. Because of this, the uptake of
such robots in key sectors is still limited compared to their vast potential uses.</p>
<p>
Industrial-grade robots demonstrate a high physical performance with fast, dexterous and robust
object handling, resembling that of humans or beyond, but only in the context of handling well-
known, modelled objects, in controlled environments.</p>
</div>
<br/>
<img src={new2} id='img2' style={{float:'left', borderRadius:'20px'}}/>
<p>
Empowering service robots with advanced physical functionalities, capabilities, and efficiency that
can allow them to achieve a wide variety of manipulation tasks in real-world environments, in a bi-
manual, human-like manner and performance remains an open challenge and needs major advances
on a series of interdisciplinary research topics.</p>
<p>
Once achieved, these advances will boost these robots’ usage and their impact in new, major sectors
of industry and services, from logistics, transport and retail, to agri-food, healthcare, and
manufacturing unlocking their true potential.
              </p>
                </h5>
             
                <br/>
              </Col>
              
            </Row>
            </Container>
            </section>
            <section id='innovations'>
            <div className="section section-team text-center" style={{textAlign:'justify',backgroundColor:'#2f5597'}}>
            <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12" style={{textAlign:'justify'}}>
              <h5 className="description" style={{color:'white',textAlign:'justify'}}>
                
              <div class="title">
    <h2 className="title" style={{color:'white',textAlign:'left'}} class="hr"><span style={{color:'whitesmoke'}}>Our Innovations</span></h2>

    <p>
              MANiBOT researchers will advance and bring together technologies ranging from multimodal
              perception, cognition and control to novel cognitive mechatronics. The project will develop:</p>
  </div>
  <div id="wrapper2">
  <div class="flip-card">
  <div class="flip-card-inner">
    <div class="flip-card-front">
      <br/>
      <img src={eye} style={{width:'100px',marginTop:'24px'}} alt="Avatar"/>
      <h5 style={{fontSize:'14px',padding:'10px',fontWeight:'600',color:'#163365',marginTop:'14px'}}>New environment understanding and object/pose recognition methods</h5> 
    </div>
    <div class="flip-card-back">
      <p style={{fontSize:'14px',padding:'14px',textAlign:'justify', hyphens:'auto'}}>empowered through an adaptive, context-aware fusion of vision, proximity and tactile sensing. This will allow fast and effective manipulation, even of unknown objects, in environments with a human presence.</p>
    </div>
  </div>
</div>

<div class="flip-card">
  <div class="flip-card-inner">
    <div class="flip-card-front">
      <br/>
      <img src={gripper} style={{width:'100px'}} alt="Avatar"/>
      <h5 style={{fontSize:'14px',padding:'10px',fontWeight:'600',color:'#163365',marginTop:'6px'}}>A novel suite of manipulation primitives</h5> 
    </div>
    <div class="flip-card-back">
      <p style={{fontSize:'14px',padding:'14px',textAlign:'justify', hyphens:'auto'}}>including non-prehensile manipulations. This will allow the transfer of diverse objects with various sizes, weights, shapes, materials and rigidities from a mobile robot, even within significant spatial constraints.</p>
    </div>
  </div>
</div>

<div class="flip-card">
  <div class="flip-card-inner">
    <div class="flip-card-front">
      <br/>
      <img src={lamp} style={{width:'80px'}} alt="Avatar"/>
      <h5 style={{fontSize:'14px',padding:'10px',fontWeight:'600',color:'#163365',marginTop:'6px'}}>Innovative cognitive mechatronics</h5> 
    </div>
    <div class="flip-card-back">
      <p style={{fontSize:'14px',padding:'14px',textAlign:'justify', hyphens:'auto'}}>fusing advanced tactile and proximity sensors with the bi- manual mobile manipulator. Energy efficiency and autonomy will be optimized, including HRI capabilities for trustworthy and efficient operation.</p>
    </div>
  </div>
</div>

<div class="flip-card">
  <div class="flip-card-inner">
    <div class="flip-card-front">
      <br/>
      <img src={brain} style={{width:'80px',marginTop:'26px'}} alt="Avatar"/>
      <h5 style={{fontSize:'14px',padding:'10px',fontWeight:'600',color:'#163365',marginTop:'6px'}}>A new approach for <br/>robot cognitive functions</h5> 
    </div>
    <div class="flip-card-back">
      <p style={{fontSize:'14px',padding:'14px',textAlign:'justify', hyphens:'auto'}}>based on multi-level robot cycles that allow learning, composing and swiftly adapting robot behaviours. This will enable complex manipulations, covering key topics of sequential manipulation of multiple objects, to achieve complex goals.</p>
    </div>
  </div>
</div>

</div>
             
            
       

      
           </h5>
              </Col>
              
            </Row>
            </Container>
            </div>
            </section>
           

            <section id='consortium'>
            <div className="section section-team text-center" style={{backgroundColor:'#ffffff'}}>
            <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12" >
              <div class="title">
    <h2 className="title" style={{color:'white',textAlign:'left'}} class="hr"><span style={{color:'#163365'}}>Partners</span></h2>
  </div>
             
             <Carousel/>
              </Col>
              
            </Row>
            </Container>
            </div>
            </section>


       
        
        <DarkFooter />
       
      </div>
    </>
  );
}

export default LandingPage;

/*
 <section id='mailchimp'>
        <div className="section section-team text-center" style={{backgroundColor:'#1e646c'}}>
        <Container>
            <Row>

              <Col className="ml-auto mr-auto text-center" md="8">
           <div id='rect'>
              <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (

      <div  id='simple' style={{padding:'40px'}}>
        <h5>Subscribe to our newsletter and stay updated.</h5>
        <SimpleForm  onSubmitted={formData => subscribe(formData)} />
        {status === "sending" && <div style={{ color: "#1e646c",position:'absolute' }}>sending...</div>}
        {status === "error" && <div style={{ color: "red" ,position:'absolute'}} dangerouslySetInnerHTML={{__html: message}}/>}
        {status === "success" && <div style={{ color: "#1e646c" }}>Subscribed !</div>}
      </div>
    )}
  />
  </div>
              </Col>
            </Row>
            </Container>
        </div>
        </section>
*/

/* <section id='cases'>
            <div className="section section-team text-center" style={{backgroundColor:'#ededed'}}>
            <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8" >
              <h2 className="title" style={{color:'#2f5597',textAlign:'left'}}>Use Cases</h2>
             
           
              </Col>
              
            </Row>
            </Container>
            </div>
            </section> */