import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';
import {Helmet} from "react-helmet";
import image1 from '../assets/img/image111.jpg'
import image2 from '../assets/img/image222.jpg'
import image3 from '../assets/img/image333.jpg'

const Article4 = () => {



  return (
    <>   
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  MANiBOT Consortium gathers in Italy to discuss latest developments       
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 27/04/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={image1} title=""  height="300" />
                <p style={{textAlign:'justify'}}>
               
                MANiBOT partners came together from the 10-12th April in Pontedera, Italy, for their second in-person project plenary meeting, marking six months since the project officially commenced. The consortium,
 <a href='https://robotics.iti.gr/index.php/en/home/'>Centre for Research and Technology Hellas</a>, 
 <a href="https://www.auth.gr/en/"> Aristotle University of Thessaloniki</a>, 
 <a href="https://m.ciop.pl/en"> Central Institute for Labour Protection - National Research Institute</a>, 
 <a href="https://www.certh.gr/root.en.aspx"> Centre for Research and Technology Hellas</a>, 
 <a href="https://www.masoutis.gr/"> Diamantis Masoutis Supermarkets</a>, 
 <a href="https://www.fraport-greece.com/eng/"> Fraport Regional Airports of Greece Management</a>, 
 <a href="https://www.santannapisa.it/en"> Sant'Anna School of Advanced Studies</a>, 
 <a href="https://digital.schwarz/en"> Schwarz Digital GmbH & Co. KG</a>,
 <a href="https://global.abb/group/en"> Asea Brown Boveri SA</a>,
 <a href="https://www.tu-darmstadt.de/index.en.jsp"> Technical University of Darmstadt</a>, 
 <a href="https://www.tuwien.at/en/"> Technical University of Vienna</a>, 
 <a href="https://www.twi-hellas.com/"> TWI Hellas</a>, 
 <a href="https://www.bristol.ac.uk/"> University of Bristol</a>, 
 <a href="https://www.ubu.es/english-version"> University of Burgos</a>was hosted by the BioRobotics Institute of Scuola Superiore Sant’Anna. 
</p>

<img style={{float:'right',margin:' 0px  0px 0px 20px'}} src={image2} title="" height="210" />
<p style={{textAlign:'justify'}}>Partners presented updates on all the work packages, focusing on those with deliverables due in the first year of the project. This ranged from the analysis of relevant existing and emerging solutions, user requirements and definition of the use cases, plans for testing the system and for the lab and pilot sites, to the first exploitation strategy and the plan for communication and dissemination. The discussions focused on planning future actions, aligning timeframes and facilitating synergies between the lines of research, thus ensuring the project is on track to meet its objectives.</p>
<img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={image3} title="" height="210" />
<p style={{textAlign:'justify'}}>Partners had the opportunity to visit SSSA’s BioRobotics Institute, learning about their ongoing research, including a demonstration of the proximity sensor they are working on. A technical workshop was organised on the last day, focusing on demonstrating the vision and soft tactile sensors being developed, as well as a more in-depth demonstration of the proximity sensor. Data was captured for future investigation. </p>
<p style={{textAlign:'justify'}}>The discussions and activities brought valuable insights for the development of the MANiBOT solution and strengthened collaboration among partners, essential for the successful progress of the project.</p> 
              
                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
    </>
  )
}

export default Article4