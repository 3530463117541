import React, { useState } from "react";
import { Fade } from "react-slideshow-image";
import FadeIn from 'react-fade-in';
import 'react-slideshow-image/dist/styles.css'
import photo0 from '../../assets/img/one.jpg'
import photo1 from '../../assets/img/two.jpg'
import photo2 from '../../assets/img/croped.png'
import photo3 from '../../assets/img/croped4.png'
import photo4 from '../../assets/img/croped2.png'
import photo5 from '../../assets/img/croped3.png'
import arm from '../../assets/img/aem.jpg'



const Slideshow = () => {
  const [previousIndex, setPreviousIndex] = useState(null);
  const [nextIndex, setNextIndex] = useState(null);

  const style = {
    textAlign: "center",
    padding: "200px 0",
    fontSize: "30px"
  };

  const properties = {
    autoplay: true,
    indicators: false,
    arrows:false,
    onChange: (previous, next) => {
      setPreviousIndex(previous);
      setNextIndex(next);
    }
  };

  return (
    <div >
      <div>
        <Fade {...properties}>
       
          <div >
           
            <img id='backimg'  src={photo5}/>
            <div style={{position:'absolute',top:'40%',fontSize:'smaller',marginLeft:'10%',maxWidth:'500px'}}>
        <h2 id='titleh2'>Advancing the physical intelligence and performance of roBOTs towards human-like bi-manual objects MANipulation</h2> </div>
          </div>
          <div>
          <img id='backimg'  src={photo3}/>
            <div style={{position:'absolute',top:'40%',fontSize:'smaller',marginLeft:'10%',maxWidth:'500px'}}>
        <h2 id='titleh2'>Enhancing robots' handling skills and ensuring adaptive responses</h2> </div>
          
          </div>
      
         
          <div>
          <img id='backimg' src={photo4}/>
          <div style={{position:'absolute',top:'40%',fontSize:'smaller',marginLeft:'10%',maxWidth:'500px'}}>
        <h2 id='titleh2'>Improving working conditions through decreasing manual labour</h2> </div>
          </div>
      
        </Fade>
      </div>
     
    </div>
  );
};

export default Slideshow;