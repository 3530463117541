import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';
import {Helmet} from "react-helmet";
import image1 from '../assets/img/image111.jpg'
import image2 from '../assets/img/image45.png'
import image3 from '../assets/img/image333.jpg'

const Article6 = () => {



  return (
    <>   
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  MANiBOT research presented at ICRA 2024       
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 12/05/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={image2} title=""  height="150" />
                <p style={{textAlign:'justify'}}>
               
                MANiBOT was proudly present at this year’s <a href='https://2024.ieee-icra.org/'>IEEE International Conference on Robotics and Automation</a> (ICRA 2024), taking place in Yokohama, Japan, from May 13-17. A paper entitled “Active-Perceptive Motion Generation for Mobile Manipulation”, was presented at the conference by authors Snehal Jauhri and Sophie Lueth and co-author Georgia Chalvatzaki, from project partne <a href='https://www.tu-darmstadt.de/universitaet/index.en.jsp'>Technical University of Darmstadt</a>. 

</p>
<p style={{textAlign:'justify'}}>
The research received funding from MANiBOT, the German Research Foundation (DFG) Emmy Noether Programme (CH 2676/1-1), and the Daimler Benz foundation.
</p>
<p style={{textAlign:'justify'}}>In this publication, the researchers introduce an active perception pipeline for mobile manipulators to generate motions that are informative toward manipulation tasks such as grasping in initially unknown, cluttered scenes.</p>

<p style={{textAlign:'justify'}}>The proposed approach, ActPerMoMa, generates robot trajectories by looking ahead a short distance at a time. It tries out different paths and calculates their effectiveness based on two goals: getting the best visual understanding of an unknown area and achieving specific tasks, such as successfully reaching and grabbing objects, in the most efficient way. </p>
<p style={{textAlign:'justify'}}>You can find the paper, demo video and code <a href='https://sites.google.com/view/actpermoma'>here</a>. </p> 
</div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
    </>
  )
}

export default Article6