import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';
import {Helmet} from "react-helmet";

const Article1 = () => {



  return (
    <>   
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  MANiBOT debuts at online showcase event
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 27/12/2023
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <p style={{textAlign:'justify'}}>
                On February 22nd MANiBOT had its public debut at an online event co-organised by <a href='https://www.linkedin.com/company/adr-association/'>Adra - AI-Data-Robotics-Association</a>, 
                <a href='https://www.linkedin.com/company/aiondemand/?originalSubdomain=fr'> AI-on-Demand Platform</a> and the <a href='https://www.linkedin.com/company/european-commission/?originalSubdomain=be'>European Commission</a>. The webinar showcased the future of innovation in AI, Data & Robotics, 
                featuring the latest EU-funded Horizon projects in the field.
</p>
<p style={{textAlign:'justify'}}>
MANiBOT was presented in the section "Pushing the limit of physical intelligence and performance (RIA)". 
Discover the presentation video below, which gives a succinct overview of the objectives, technology and expected impact of the project.
 All the other projects that featured in the webinar can be checked out in the recording on the event webpage <a href='https://adra-e.eu/events/launch-event-showcasing-future-innovation-ai-data-and-roboticshttps:/adra-e.eu/events/launch-event-showcasing-future-innovation-ai-data-and-robotics'>here</a>, and the 
 agenda with the timing can be consulted <a href='https://adra-e.eu/events/launch-event-showcasing-future-innovation-ai-data-and-robotics'>here</a>.
</p>

                </div>
                
                <iframe width="360" height="215" src="https://www.youtube.com/embed/EtJMzYoiBHI?si=pC04ndJqE4V3Gz7B" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                <br/>
                <br></br><p style={{textAlign:'justify'}}>
                Want to know more about MANiBOT?<br/>Watch our video from the <a href='https://adra-e.eu/events/launch-event-showcasing-future-innovation-ai-data-and-robotics'>ADRA</a>
                </p>
              
                </div>
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
    </>
  )
}

export default Article1