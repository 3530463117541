import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';
import {Helmet} from "react-helmet";
import image1 from '../assets/img/Picture2.jpg'
import image2 from '../assets/img/Picture3.jpg'
import image3 from '../assets/img/Picture4.jpg'

const Article2 = () => {



  return (
    <>   
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  MANiBOT project commences journey to revolutionise mobile robots                 
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 27/02/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={image1} title=""  height="300" />
                <p style={{textAlign:'justify'}}>
               
The MANiBOT project, a pioneering initiative aimed at empowering bimanual mobile robots for challenging manipulation tasks,
 was launched in Thessaloniki, Greece, on November 14-15, 2023. Representatives from all 13 members of the consortium 
 (<a href="https://www.auth.gr/en/">Aristotle University of Thessaloniki</a>, 
 <a href="https://m.ciop.pl/en"> Central Institute for Labour Protection - National Research Institute</a>, 
 <a href="https://www.certh.gr/root.en.aspx"> Centre for Research and Technology Hellas</a>, 
 <a href="https://www.masoutis.gr/"> Diamantis Masoutis Supermarkets</a>, 
 <a href="https://www.fraport-greece.com/eng/"> Fraport Regional Airports of Greece Management</a>, 
 <a href="https://www.santannapisa.it/en"> Sant'Anna School of Advanced Studies</a>, 
 <a href="https://digital.schwarz/en"> Schwarz Digital GmbH & Co. KG</a>,
 <a href="https://digital.schwarz/en"> Asea Brown Boveri SA</a>,
 <a href="https://www.tu-darmstadt.de/index.en.jsp"> Technical University of Darmstadt</a>, 
 <a href="https://www.tuwien.at/en/"> Technical University of Vienna</a>, 
 <a href="https://www.twi-hellas.com/"> TWI Hellas</a>, 
 <a href="https://www.bristol.ac.uk/"> University of Bristol</a>, 
 <a href="https://www.ubu.es/english-version"> University of Burgos</a>) convened at host CERTH’s premises to reinforce the project's objectives, discuss procedures and establish roadmaps for the upcoming months’ work.
</p>
<img style={{float:'right',margin:' 0px  0px 0px 20px'}} src={image2} title="" height="210" />
<p style={{textAlign:'justify'}}>
During the meeting, technical presentations also delved into various work packages, highlighting tasks, objectives, and responsibilities. Topics included user requirements, visuo-tactile inference, federated learning, motion coordination, human-robot interaction, sensor and mechatronics integration and demonstration planning. Partners exchanged insights and outlined action points to ensure smooth collaboration and progress.
</p>


<p style={{textAlign:'justify'}}>
Participants had the opportunity to visit the Fraport Greece (FG) pilot site at Thessaloniki Airport (SKG). They learnt about FG’s latest developments and the needs and discussed the logistical challenges of baggage handling with company representatives, gaining first-hand insights into scenarios essential for the project. 
</p> 
<img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={image3} title="" height="200" />
<p style={{textAlign:'justify'}}>
On day two partners were also afforded the chance to discover the latest innovations of CERTH’s <a href='https://robotics.iti.gr/index.php/en/home/'>Robotics Lab</a>. 
The visit showcased a selection of their technologies and ongoing research, 
with a focus on advances in autonomous navigation and intelligent manipulation. </p>
<p style={{textAlign:'justify'}}>With a diverse consortium of committed, expert partners and clear roadmaps in place, the project is positioned to make significant strides in the robotics landscape.
</p>

     
              
                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
    </>
  )
}

export default Article2